/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import { SnackbarProvider } from "notistack"

import PageTitle from "../components/header/page-title"
import Header from "./header/header"
import Footer from "./footer"
import "./layout.css"

const Layout = ({ children, title, image, breadcrumbs, excerpt }) => {
  const [contactFormToggle, setContactFormToggle] = useState(false)
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header
        siteTitle={data.site.siteMetadata?.title || `Title`}
        setContactFormToggle={setContactFormToggle}
      />
      <SnackbarProvider maxSnack={3}>
        <PageTitle
          title={title}
          image={image}
          breadcrumbs={breadcrumbs}
          excerpt={excerpt}
        />

        <main>{children}</main>
      </SnackbarProvider>
      <Footer
        contactFormStatus={contactFormToggle}
        setContactFormToggle={setContactFormToggle}
      />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
