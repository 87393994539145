import React from "react"

import { Container, Grid, makeStyles } from "@material-ui/core"
import ImageFrame from "../../../static/images/team-frame.png"
import titleBg from "../../../static/images/animatedHero/background.png"

const useStyles = makeStyles(theme => ({
  container: {
    position: "relative",
    padding: 0,
    // "&::before": {
    //   content: `""`,
    //   backgroundImage: `url(${titleBg})`,
    //   position: "absolute",
    //   top: -130,
    //   right: 0,
    //   width: "calc(100% - 70%)",
    //   height: 604,
    //   backgroundSize: "contain",
    //   zIndex: -1,
    // },
  },
  iamgeFrame: {
    backgroundImage: `url(${titleBg})`,
  },
  title: {
    backgroundImage: `url(${titleBg})`,
  },
  image: {
    borderRadius: 200,
    filter: "grayscale(1)",
    height: "auto",
    width: 200,
    // margin: "0 10px 0 auto",
    // marginRight: 30,
    borderRadius: 200,
    backgroundSize: "cover",
    backgroundImage: `url(${titleBg})`,
    backgroundPosition: "center",
    [theme.breakpoints.down("sm")]: {
      width: 120, // 80
      marginRight: "initial",
      // top: "-42px !important",
      position: "relative",
      maxWidth: "32%",
    },
  },
  imageContainer: {
    // height: 650,
    // display: "flex",
    position: "relative",
    // width: "calc(100% - 70%)",
    // flexDirection: "row-reverse",
    // "&::before": {
    //   content: `""`,
    //   backgroundImage: `url(${titleBg})`,
    //   position: "absolute",
    //   top: -130,
    //   right: 0,
    //   width: "100%",
    //   height: "100%",
    //   backgroundSize: "cover",
    //   zIndex: -1,
    //   [theme.breakpoints.down("sm")]: {
    //     top: -62,
    // },
    // },
    [theme.breakpoints.down("sm")]: {
      // height: 210,
      right: -16,
    },
    // backgroundImage: `url(${titleBg})`,
    // position: "absolute",
    // top: -130,
    // right: 0,
    // backgroundSize: "contain",
    // zIndex: -1,
  },
  rightSideSection: {
    width: 650,
    maxWidth: "100%",
    maxHeight: "100%",
    "& .MuiGrid-container": {
      marginRight: 20,
    },
  },
  bgImage: {
    float: "right",
    position: "relative",
    top: -130,
    zIndex: -1,
    [theme.breakpoints.down("md")]: {
      top: -62,
    },
  },
  profileImageContainer: {
    position: "absolute",
    top: 0,
    right: 0,
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center",
  },
  profileName: {
    fontWeight: 600,
    fontSize: 18,
  },
  profilePosition: {
    fontWeight: 600,
    // fontSize: 18,
  },
  // profileDetails: {
  //   color: "#fff"
  // }
}))

export default function PageTitle({ title, image, excerpt }) {
  const classes = useStyles()
  return (
    <Container maxWidth={false} className={classes.container}>
      <Grid container>
        <Grid item xs={6} sm={6}>
          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            className="intro go"
            viewBox="0 0 400 136"
            x="0px"
            y="0px"
            style={{
              enableBackground: "new 0 0 342.2 259.3",
            }}
            xmlSpace="preserve"
          >
            <text
              textAnchor="start"
              x={100}
              y={30}
              className="text textStroke"
              clipPath="url(#text1)"
            >
              {title}
            </text>
            <text
              textAnchor="start"
              x={100}
              y={30}
              className="text textStroke textStroke2"
              clipPath="url(#text1)"
            >
              {title}
            </text>
            <defs>
              <clipPath id="text1">
                <text textAnchor="start" x={100} y={30} className="text">
                  {title}
                </text>
              </clipPath>
            </defs>
          </svg> */}
        </Grid>
        <Grid item xs={false} sm={false}></Grid>
        <Grid
          item
          xs={6}
          sm={6}
          style={{ textAlign: "right" }}
          className={classes.imageContainer}
        >
          <img
            src={titleBg}
            alt="pagetitle"
            className={`${classes.rightSideSection} ${classes.bgImage}`}
          />
          <Container
            className={`${classes.rightSideSection} ${classes.profileImageContainer}`}
          >
            {image && (
              <img src={image} alt="pagetitle" className={classes.image} />
            )}
            <Grid
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-end"
              className={classes.profileDetails}
            >
              <Grid item className={classes.profileName}>{title}</Grid>
              <Grid item className={classes.profilePosition}>
                {/* {excerpt} */}
                {excerpt && excerpt.replace(/<[^>]*>?/gm, "")}
              </Grid>
              <Grid item></Grid>
            </Grid>
          </Container>
        </Grid>
      </Grid>
    </Container>
  )
}
