import React from "react"
import {
  Grid,
  // Button,
  Hidden,
  Container,
  makeStyles,
} from "@material-ui/core"

// import Drawer from "./drawer"

import Button from "../Button"

import logoFull from "../../../static/images/logo-full.png"

const useStyles = makeStyles(theme => ({
  header: {
    paddingTop: 10,
    paddingBottom: 4,
  //   backgroundColor: "#fff",
  //   boxShadow: "2px 2px 5px 0px rgb(0 0 0 / 20%)",
  },
  // NOTE: Desabled for header style 2
  fullWidthContainer: {
    maxWidth: "100%",
    padding: 0,
    margin: 0,
  },
  container: {
    maxWidth: "92%",
    margin: "0 auto",
  },
  footerBg: {
    backgroundColor: "#1a1c20",
    "& .MuiGrid-container": {
      justifyContent: "center",
    },
  },
  logo: {
    maxWidth: 120,
    "& .MuiGrid-container": {
      justifyContent: "center",
    },
  },

  headerSecondSection: {
    direction: "rtl",
    [theme.breakpoints.up("md")]: {
      marginTop: 24,
    },
  },
  buttonMobile: {
    fontSize: 12,
    color: "#fff",
    borderWidth: 0,
    borderRadius: 10,
    transition: "0.3s",
    fontWeight: "bold",
    padding: "12px 24px 10px",
    boxShadow: "2px 2px 5px 0px rgb(0 0 0 / 20%)",
    backgroundImage: "linear-gradient(248deg, #00a69c 0%, #29b473 91%)",
    "& .MuiButton-label": {
      lineHeight: 0.7,
    },
    "&:hover": {
      transition: "0.3s",
      backgroundImage: "linear-gradient(248deg, #29b473 0%, #00a69c 91%)",
    },
  },
  containerMobile: {
    maxWidth: "97%",
    margin: "10px auto 0",
    [theme.breakpoints.between("sm", "md")]: {
      direction: "rtl",
      textAlign: "left",
    },
  },
  logoTextContainer: {
    display: "flex",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
  },
  logoText: {
    maxWidth: 180,
  },
  menuButton: {
    marginTop: 8,
    color: "#fff",
    float: "right",
    borderWidth: 0,
    borderRadius: 5,
    transition: "0.3s",
    padding: "4px 12px",
    marginLeft: theme.spacing(2),
    boxShadow: "2px 2px 5px 0px rgb(0 0 0 / 20%)",
    backgroundImage: "linear-gradient(248deg, #29b473 0%, #00a69c 91%)",
    "& .MuiButton-label": {
      lineHeight: 0.7,
    },
    "&:hover": {
      borderRadius: 10,
      transition: "0.3s",
      backgroundImage: "linear-gradient(248deg, #00a69c 0%, #29b473 91%)",
    },
  },
}))

const Header = ({ setContactFormToggle, contactFormToggle }) => {
  const classes = useStyles()

  const handleClickFormButton = () => {
    setContactFormToggle(!contactFormToggle)
  }

  return (
    <>
      <Hidden mdUp>
        <Container maxWidth={false} className={classes.header}>
          <Container className={classes.containerMobile}>
            <Grid container>
              <Grid item sm={6} sx={6} />
              <Grid item sm={6} sx={6} className={classes.headerSecondSection}>
                <Button
                  onClick={() => handleClickFormButton()}
                  variant="outlined"
                  className={classes.buttonMobile}
                >
                  Contact us
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Container>
      </Hidden>
      <Hidden smDown>
        <Container maxWidth={false} className={classes.header}>
          <Container className={classes.container}>
            <Grid container>
              <Grid item sm={6} sx={6}>
                <img src={logoFull} alt="logo" className={classes.logo} />
              </Grid>
              <Grid item sm={6} sx={6} className={classes.headerSecondSection}>
                <Button
                  onClick={() => handleClickFormButton()}
                  variant="outlined"
                  className={classes.button}
                >
                  Contact us
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Container>
      </Hidden>
    </>
  )
}
export default Header
